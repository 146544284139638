import * as React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { withLayout, LayoutProps } from '../../components/Layout';
import { FixedMasthead } from '../../components/Masthead/FixedMasthead.component';
import styled from '../../styled-components';
import {
    Constrain,
    pullBackSmall,
    pullbackMarginToNormalConstrainFromNarrow,
    pullbackMarginsWide,
    LinkArrow,
    HeadingLevel3
} from '../../components/ui';
import CallToContact from '../../components/CallToContact/CallToContact.component';
import { media } from '../../utilities';
import earthIllustration from './images/earth-illustration.svg';
import ethicalTechSocialImage from './images/ethical-impact-social.jpg';
import etaPreferredDeveloperBadge from './images/ETA-badge-white.svg';
import dollarIllustration from './images/dollar-illustration.svg';
import backgroundSmall from '../../images/deep-sea-blue--small.jpg';
import voteForPoliciesMini from '../../images/illustrations/vote-for-policies-mini.svg';
import { QuerySiteArgs } from '../../generated/graphql-types';
import { ContentSummary } from '../../components/ContentSummary/ContentSummary.component';
import { AfterContentContact } from '../../components/AfterContentContact/AfterContentContact.component';

const ScopedPageHeader = HeadingLevel3.withComponent('h3');

const EthicalImpactPageMastHead = styled.div`
    margin-top: ${({ theme }) => theme.spacing.l};
    margin-bottom: ${({ theme }) => theme.spacing.l};

    h1 {
        font-family: ${({ theme }) => theme.fontNew.secondary.type};
        font-size: ${({ theme }) => theme.fontNew.secondary.size.level4.size};
        line-height: ${({ theme }) =>
            theme.fontNew.secondary.size.level4.lineHeight};
        color: ${({ theme }) => theme.colors.deepBlue.color};
        font-weight: normal;
        margin-bottom: 1.5rem;
        span {
            position: relative;

            &:before {
                content: '';
                top: 15px;
                bottom: 15px;
                left: -5px;
                right: -5px;
                position: absolute;
                z-index: -1;
                background-color: ${({ theme }) => theme.colors.green.color};
            }
        }
        ${media.xl`
            font-size: ${({ theme }) =>
                theme.fontNew.secondary.size.level2.size};
            line-height: ${({ theme }) =>
                theme.fontNew.secondary.size.level2.lineHeight};
        `}
    }

    h2 {
        font-size: ${({ theme }) => theme.fontNew.primary.size.level3.size};
        line-height: ${({ theme }) =>
            theme.fontNew.primary.size.level3.lineHeight};
        em {
            font-style: normal;
            border-bottom: 2px solid ${({ theme }) => theme.colors.green.color};
            /* white-space: nowrap; */
        }

        ${media.xl`
            font-size: ${({ theme }) => theme.fontNew.primary.size.level2.size};
            line-height: ${({ theme }) =>
                theme.fontNew.primary.size.level2.lineHeight};
        `}
    }

    img {
        display: block;
        margin: 0 ${pullBackSmall};
        width: calc(100% + 6rem);
        ${media.l`
            ${pullbackMarginsWide}
            width: calc(100% + 12rem);
        `}

        ${media.xl`
            ${pullbackMarginToNormalConstrainFromNarrow}
            width: calc(100% + 19.6rem);
        `}
    }
`;

const EthicalImpactPageContent = styled.div`
    .intro {
        font-size: ${({ theme }) => theme.fontNew.primary.size.level7.size};
        line-height: ${({ theme }) =>
            theme.fontNew.primary.size.level7.lineHeight};
        font-weight: 500;

        ${media.xl`
            font-size: ${({ theme }) => theme.fontNew.primary.size.level5.size};
            line-height: ${({ theme }) =>
                theme.fontNew.primary.size.level5.lineHeight};
        `}
    }

    .spacer {
        margin-bottom: ${({ theme }) => theme.spacing.l};

        ${media.xl`
            margin-bottom: ${({ theme }) => theme.spacing.xl};
        `}
    }

    .hidden {
        display: none;
    }

    ${ScopedPageHeader} {
        margin-top: ${({ theme }) => theme.spacing.l};

        ${media.l`
            margin-top: ${({ theme }) => theme.spacing.xl};
        `}
    }

    .heading3 {
        font-size: ${({ theme }) => theme.fontNew.primary.size.level5.size};
        line-height: ${({ theme }) =>
            theme.fontNew.primary.size.level5.lineHeight};
        margin-top: ${({ theme }) => theme.spacing.l};
        margin-bottom: ${({ theme }) => theme.spacing.m};

        ${media.xl`
            font-size: ${({ theme }) => theme.fontNew.primary.size.level4.size};
            line-height: ${({ theme }) =>
                theme.fontNew.primary.size.level4.lineHeight};
            margin-top: ${({ theme }) => theme.spacing.xl};
            margin-bottom: ${({ theme }) => theme.spacing.l};
        `}
    }

    .heading4 {
        font-size: ${({ theme }) => theme.fontNew.primary.size.level7.size};
        line-height: ${({ theme }) =>
            theme.fontNew.primary.size.level7.lineHeight};
        margin-top: 2rem;
        font-weight: 500;
        margin-bottom: 1rem;

        ${media.xl`
            font-size: ${({ theme }) => theme.fontNew.primary.size.level5.size};
            line-height: ${({ theme }) =>
                theme.fontNew.primary.size.level5.lineHeight};
        `}
    }

    ul {
        margin-bottom: ${({ theme }) => theme.spacing.m};
        padding-left: 2rem;
        position: relative;

        & > li {
            list-style: none;
            margin-bottom: 1rem;
            position: relative;

            &:before {
                content: '';
                height: 0.9rem;
                width: 0.9rem;
                display: block;
                left: -2rem;
                top: 0.8rem;
                position: absolute;
                background-color: ${({ theme }) => theme.colors.green.color};

                ${media.l`
                    top: 1.2rem;
                `}
            }
        }
    }

    .split-list {
        ${media.m`
            columns: 2;
            column-gap: 6rem;
        `}
    }

    .how-we-can-help {
        margin-bottom: 6rem;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            ${media.l`
                display: flex;
            `}

            h4 {
                font-family: ${({ theme }) => theme.fontNew.secondary.type};
                font-weight: 300;
                margin-bottom: 0;
                font-size: ${({ theme }) =>
                    theme.fontNew.secondary.size.level5.size};
                ${media.xl`
                    font-size: ${({ theme }) =>
                        theme.fontNew.secondary.size.level4.size};
                `}
            }

            p {
                margin-bottom: 0;
            }

            li {
                border-left: 2px solid
                    ${({ theme }) => theme.colors.green.color};

                padding: 0 3rem;

                &:last-child {
                    padding-right: 0;
                }

                &:before {
                    display: none;
                }
                ${media.l`
                    flex: 1;
                    margin-bottom: 0;
                `}
            }
        }
    }
`;

const WhatWeCanOffer = styled.div`
    margin-bottom: 6rem;

    img {
        float: right;
        width: 30%;
    }

    p {
        margin: 0;
    }

    ${media.m`
        display: flex;
        flex-direction: row-reverse;
        align-items: center;

        img {
            float: none;
            width: 100%;
            max-width: 100px;
        }
    `}
`;

const ETAPanel = styled.div`
    background-image: url(${backgroundSmall});
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
    font-weight: 300;
    padding: 3rem;
    margin-bottom: 6rem;

    > img {
        margin-bottom: 1.5rem;
        max-width: 100%;
    }

    ${media.m`
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 9rem;

        > *:first-child {
            flex: 2;
            width: 300px;
            margin-left: 1.5rem;
        }

        > *:last-child {
            flex: 3;
        }
    `}

    h4 {
        margin-top: 0;
        margin-bottom: 1.5rem;
        font-weight: 500;
        font-size: ${({ theme }) => theme.fontNew.primary.size.level5.size};
        line-height: ${({ theme }) =>
            theme.fontNew.primary.size.level5.lineHeight};

        ${media.xl`
            font-size: ${({ theme }) => theme.fontNew.primary.size.level3.size};
            line-height: ${({ theme }) =>
                theme.fontNew.primary.size.level3.lineHeight};
        `}
    }

    p:last-child {
        margin: 0;
    }
`;

const ImpactWereHavingContainer = styled.div`
    background: ${({ theme }) => theme.colors.deepBlue.color};
    padding: 6rem 0 1rem 0;
    color: #fff;

    a {
        color: #fff;
    }

    .heading {
        font-family: ${({ theme }) => theme.fontNew.secondary.type};
        font-weight: 500;
        font-size: ${({ theme }) => theme.fontNew.secondary.size.level4.size};

        ${media.l`
            font-size: ${({ theme }) =>
                theme.fontNew.secondary.size.level3.size};
        `}
    }

    .featured-impact {
        margin-bottom: 3rem;
        overflow: hidden;
    }

    .in-progress-impact {
        margin-bottom: 6rem;

        ${media.m`
            display: flex;
            margin: 0 -3rem 6rem -3rem;
        `}

        & > * {
            margin-bottom: 3rem;

            ${media.m`
                flex: 1;
                margin-bottom: 0;
                padding: 0 3rem;
            `}
        }
    }

    ${AfterContentContact} {
        margin-bottom: 0;
        padding-top: 1.5rem;
        position: relative;

        & > &:last-child {
            margin-bottom: 0;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            width: 170px;
            background-color: ${({ theme }) => theme.colors.green.color};
            height: 2px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
`;

interface IEthicalImpactPageProps extends LayoutProps {
    data: {
        site: QuerySiteArgs;
    };
}

const EthicalImpact = ({ data, location }: IEthicalImpactPageProps) => {
    const metaTitle = 'Making an ethical impact';
    const metaDescription = 'Using our expertise as a force for good.';
    const metaSocialImagePath =  data.site.siteMetadata.siteUrl + ethicalTechSocialImage;
    return (
        <>
            <Helmet>
                <title>Making an ethical impact with digital products</title>
                <meta
                    name="description"
                    content={metaDescription}
                />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@wemakewaves" />

                <meta
                    name="twitter:title"
                    content={metaTitle}
                />
                <meta
                    name="twitter:description"
                    content={metaDescription}
                />

                <meta
                    name="twitter:image"
                    content={metaSocialImagePath}
                />

                <meta property="og:title" content={metaTitle} />

                <meta
                    property="og:description"
                    content={metaDescription}
                />
                <meta property="og:type" content="website" />
                <meta
                    property="og:url"
                    content={`${
                        data.site.siteMetadata.siteUrl
                    }/making-an-ethical-impact`}
                />
                <meta
                    property="og:image"
                    content={metaSocialImagePath}
                />
            </Helmet>
            <FixedMasthead />

            <EthicalImpactPageMastHead>
                <Constrain type="narrow">
                    <h1 className="heading1">
                        <span>Making an Ethical Impact</span>
                    </h1>
                    <h2 className="heading2">
                        Using our expertise as a <em>force for good</em>
                    </h2>
                    <img
                        src={earthIllustration}
                        alt="Illustration of our planet"
                    />
                </Constrain>
            </EthicalImpactPageMastHead>
            <EthicalImpactPageContent>
                <Constrain type="narrow">
                    <p className="intro">
                        Billions are spent on technology every year but only a
                        small fraction is used to tackle the social and
                        environmental challenges we face. We're no longer
                        prepared to stand by and watch as underinvestment leads
                        to many important needs going unmet.
                    </p>
                    <ScopedPageHeader>
                        Let's work together&hellip;
                    </ScopedPageHeader>
                    <p>
                        If you have an idea for a digital product that will have
                        a positive impact on the environment or society:
                    </p>

                    <ul className="split-list">
                        <li>Environmental sustainability</li>
                        <li>Healthcare &amp; wellbeing</li>
                        <li>
                            Gender, ethnic, and socio-economic representation
                        </li>
                        <li>Social responsibility</li>
                        <li>Privacy</li>
                        <li>Charity / non-profit</li>
                    </ul>
                </Constrain>

                <Constrain type="normal">
                    <div className="how-we-can-help">
                        <ScopedPageHeader>
                            Giving ethical tech a helping hand
                        </ScopedPageHeader>
                        <ul>
                            <li>
                                <h4>Maximise your budget</h4>
                                <p>
                                    Identify the core value in your proposition
                                    and the critical path to deliver it.
                                </p>
                            </li>
                            <li>
                                <h4>Understand user needs</h4>
                                <p>
                                    Take action on insights from user interviews
                                    and usability testing.
                                </p>
                            </li>
                            <li>
                                <h4>Measure your impact</h4>
                                <p>
                                    Make decisions based on the evidence from
                                    experiments we run on your assumptions.
                                </p>
                            </li>
                        </ul>
                    </div>
                </Constrain>

                <Constrain type="narrow">
                    <WhatWeCanOffer>
                        <img
                            src={dollarIllustration}
                            alt="Ethical Tech Alliance preferred developer badge"
                        />

                        <p>
                            To accelerate growth in ethical tech, we're offering{' '}
                            <strong>reduced rates</strong> for projects with the
                            ambition to use technology to tackle social and
                            environmental challenges. We also have access to
                            some <strong>grant funding</strong> via our
                            partnership with the Ethical Tech Alliance.
                        </p>
                    </WhatWeCanOffer>
                </Constrain>

                <Constrain type="normal">
                    <ETAPanel>
                        <img
                            src={etaPreferredDeveloperBadge}
                            alt="Ethical Tech Alliance preferred developer badge"
                        />

                        <div>
                            <h4>
                                We're a preferred development partner of the
                                Ethical Tech Alliance
                            </h4>
                            <p>
                                The purpose of the Ethical Tech Alliance is to
                                champion ethical tech by reducing barriers to
                                getting ethical tech built
                            </p>
                            <p>
                                <LinkArrow
                                    to="https://ethicaltechalliance.org"
                                    color="green"
                                    arrowColor="green"
                                >
                                    Learn more
                                </LinkArrow>
                            </p>
                        </div>
                    </ETAPanel>
                </Constrain>

                <ImpactWereHavingContainer>
                    <Constrain type="normal">
                        <h2 className="heading">The impact we're having</h2>
                        <div className="featured-impact">
                            <ContentSummary
                                title="Vote for policies"
                                imageAlign="left"
                                summary="Helping voters make an informed decision about who to vote for and increase voter turnout in the General Election"
                                type="Featured"
                                highlightColour="green"
                                image={{
                                    src: voteForPoliciesMini,
                                    alt:
                                        'Illustration of ballot paper being ticked'
                                }}
                                slug="/case-studies/vote-for-policies/"
                            />
                        </div>

                        <div className="in-progress-impact">
                            <ContentSummary
                                title="ShareTrace"
                                highlightColour="green"
                                summary="Helping people understand their Covid-19 exposure risk whilst keeping their data private"
                                type="In progress"
                                slug="/case-studies/sharetrace-covid-19-app/"
                            />
                            <ContentSummary
                                title="NHS"
                                highlightColour="green"
                                summary="Reducing the number of cases of preventable mortality and damage to newborns"
                                type="Complete"
                                slug="/case-studies/nhs-electronic-fetal-monitoring/"
                            />
                            <ContentSummary
                                title="E-LfH"
                                highlightColour="green"
                                summary="Improving access to online healthcare education for 3 million healthcare professionals"
                                type="In progress"
                            />
                        </div>

                        <AfterContentContact />
                    </Constrain>
                </ImpactWereHavingContainer>
            </EthicalImpactPageContent>
            <CallToContact />
        </>
    );
};

export const query = graphql`
    query {
        site {
            siteMetadata {
                siteUrl
            }
        }
    }
`;

export default withLayout(EthicalImpact);
